import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../service/auth.service';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { PageScrollService } from 'ngx-page-scroll-core';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
  DocumentReference
} from '@angular/fire/firestore';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  results: Observable<string[]>;
  contactForm: FormGroup;

  signUpClickedOnce = false;
  hasLoggedIn = false;

  // protected aFormGroup: FormGroup;


  constructor(private router: Router,
    public auth: AuthService,
    private AngularFireAuth: AngularFireAuth,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private pageScrollService: PageScrollService, @Inject(DOCUMENT) private document: any,
    private afs: AngularFirestore) { }

  public siteKey: any;
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;

  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: 'image' | 'audio';
  public useGlobalDomain: boolean = false;

  activeSection = 1;


  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;

  id = '4Pi9IpFgUP8'
  playerVars = {
    cc_lang_pref: 'en',
  };
  private player;
  private ytEvent;

  onStateChange(event) {
    this.ytEvent = event.data;
  }
  savePlayer(player) {
    this.player = player;
  }

  playVideo() {
    this.player.playVideo();
  }

  pauseVideo() {
    this.player.pauseVideo();
  }

  ngOnInit() {
    this.contactForm = new FormGroup({
      nameInput: new FormControl(null, Validators.required),
      emailInput: new FormControl(null, [Validators.required, Validators.email]),
      message: new FormControl(null, Validators.required),
      // terms: new FormControl(null, Validators.requiredTrue),
      recaptcha: new FormControl('', Validators.required)
    });
    // this.aFormGroup = this.formBuilder.group({
    //   recaptcha: ['', Validators.required]
    // });
    this.siteKey = "6LeY2oseAAAAAA2ylUttrLaGLHMqCJnWu_1fS48T";
    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: '.theEnd',
    });
  }

  get nameInput() { return this.contactForm.get('nameInput'); }
  get emailInput() { return this.contactForm.get('emailInput'); }
  get message() { return this.contactForm.get('message'); }
  get terms() { return this.contactForm.get('terms'); }

  onSubmit(): void {
    this.addSignUp();
  }


  scrollTo(event: HTMLElement, index: number) {
    this.pageScrollService.scroll({
      scrollTarget: event,
      document: this.document
    });

    this.activeSection = index;
  }

  addSignUp() {
    if (!this.signUpClickedOnce) {
      if (!this.hasLoggedIn) {
        this.auth.anonymousLogin();
        this.checkAuth();
      }
    } else {
      console.log("SignUp button already clicked");
    }
  }

  checkAuth() {
    this.signUpClickedOnce = true;
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) {
        this.hasLoggedIn = true
        this.afs.collection('mail').doc().set({
          to: "info@smartcontx.com",
          message: {
            subject: "Contact message from SmartContx.",
            text: "Name: " + this.nameInput.value + "  <br />  Email: " + this.emailInput.value + "  <br />  Message: " + this.message.value,
            html: "Name: " + this.nameInput.value + "  <br />  Email: " + this.emailInput.value + "  <br />  Message: " + this.message.value,
          },
        })
          .then(function () {
            console.log("Document successfully written!");
            // this.signUpClickedOnce = true;
          })
          .catch(function (error) {
            console.error("Error writing document: ", error);
          });
      }
      else if (!this.hasLoggedIn) {
        console.log("user has NOT logged in");
        // this.toastr.warning('Please Login', 'LOGIN STATUS', {
        //   timeOut: 2000,
        // });
      }
    })
    this.toastr.success('Message sent', '', {
      timeOut: 4000,
    });
  }

  termsClicked() {
    this.router.navigate(['/terms']);
  }


  navbarOpen = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

}
