<header id="top-section">
  <nav class="navbar navbar-expand-lg bg-dark navbar-dark navbar-static-top">
    <a class="navbar-brand" routerLink="/home">SMARTCONTX</a>
    <!-- Collapsible content -->

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar"
      (click)="toggleNavbar()">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }" id="collapsibleNavbar">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': activeSection === 1}" pageScroll
            (click)="scrollTo(aboutus, 1)">ABOUT US
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': activeSection === 2}" pageScroll
            (click)="scrollTo(ourservices, 2)">OUR SERVICES
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': activeSection === 3}" pageScroll
            (click)="scrollTo(latestprojects, 3)">LATEST PROJECTS
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': activeSection === 4}" pageScroll (click)="scrollTo(blog, 4)">BLOG
          </a>
        </li>
        <li class="nav-item">
          <!-- <a class="nav-link" href="#CONTACT US" pageScroll>CONTACT US</a> -->
          <a class="nav-link" [ngClass]="{'active': activeSection === 5}" pageScroll
            (click)="scrollTo(contactus, 5)">CONTACT US
          </a>
        </li>
      </ul>
    </div>
  </nav>
</header>


<header class="h-50">
  <img src="/assets/img/header_logo.jpeg" class="img-fluid" id="header_logo" alt="">
  <div class="container" id="header" text="center">
    <div class="row no-gutters">
      <div class="col-12 col-md-auto">
        <mdb-card style="background-color: black;">
          <!-- Card image -->
          <!-- <img src="/assets/img/header_logo.jpeg" class="img-fluid" id="header_logo" alt=""> -->
          <div class="text-white text-center py-5 px-4 md">
            <h1 class="card-title h1 pb-2 mb-5 mt-3 text-white font-weight-bold">
              <strong>AI & Next Generation Cloud
              </strong>
            </h1>
            <div>
              <h4 class="mx-5 mb-3" id="header_text" style="font-style: italic;">‘The convergence of artificial
                intelligence, cloud computing, mobile computing, big data, and analytics
                is reshaping industries and creating unprecedented opportunities.’</h4>
              <br>
              <br>
              <h4 class="mx-5 mb-5" id="header_text">
                As your strategic partner, we guide you through the transformative landscape of AI and next-generation
                cloud technologies. Our expertise empowers your organisation to build new business models, optimise
                operations, and unlock competitive advantages.
              </h4>
            </div>
          </div>
        </mdb-card>
      </div>
    </div>
  </div>


  <!--/Card image-->
  <!-- Jumbotron -->
  <div class="jumbotron text-center">

    <!-- Title -->
    <!-- <h1 class="h4-responsive font-weight-bold text-center my-5">Who are SmartContx?</h1> -->
    <!-- <h4 class="card-title h2 pb-2 font-weight-bold"><strong>Who are SmartContx?</strong> -->
    <div #aboutus class="scroll-outer"></div>
    <div class="col-12 col-md-auto mb-5">
      <mdb-card style="background-color: white;">
        <h1 class="card-title h1 pb-2 mt-4 dark-grey-text">About SmartContx</h1>
        <div class="text-center px-4 md">
          <h4>
            As businesses seek to harness the full potential of AI and cloud technologies, they need expert guidance to
            navigate the complexities of implementation and maximise their return on investment.
            <br><br>
            SmartContx offers deep
            expertise across Generative AI, ML, and cloud platforms, providing comprehensive consultancy services to
            help organisations achieve
            agility, innovation, and long-term success.
            <br>
            <br>
          </h4>
        </div>
      </mdb-card>
    </div>

    <h1 #ourservices class="card-title h1 pb-2 mt-4 dark-grey-text scroll-outer">Our Services</h1>
    <div class="text-center px-4 md">
      <h4>
        We provide Cloud Consultancy services for clients within the retail, finance, energy, automotive, and
        pharmaceutical industries.
        <br>
        <br>
      </h4>
    </div>

    <div class="card-deck mb-5">
      <mdb-card>
        <mdb-card-body>
          <mdb-card-title>
            <h5>Customer Experience (CX) Design</h5>
          </mdb-card-title>
          <mdb-card-text>We help ambitious clients transform the way they communicate with their customers and
            communities. Designing and
            building customer-centric digital products that create a seamless omnichannel user journey throughout your
            brand.</mdb-card-text>
        </mdb-card-body>
      </mdb-card>

      <mdb-card>
        <mdb-card-body>
          <mdb-card-title>
            <h5>Cloud-Native Product Development</h5>
          </mdb-card-title>
          <mdb-card-text>A cloud native application is built to live in the cloud. It auto-provisions, auto-scales, and
            is auto-resilient,
            delivering maximum value to a business and its end users. We deliver greenfield cloud-native software
            projects
            that provide differentiated services to your customers.
          </mdb-card-text>
        </mdb-card-body>
      </mdb-card>

      <mdb-card>
        <mdb-card-body>
          <mdb-card-title>
            <h5>Contextual AI</h5>
          </mdb-card-title>
          <mdb-card-text>Our expertise lies in crafting AI solutions that go beyond simple responses. We design systems
            capable of understanding the nuances of complex contexts, from customer preferences and historical
            interactions to external factors like time and location. This enables us to deliver personalised experiences
            that resonate with your users, fostering deeper connections and driving customer
            satisfaction.</mdb-card-text>
        </mdb-card-body>
      </mdb-card>


      <mdb-card>
        <mdb-card-body>
          <mdb-card-title>
            <h5>Process Optimisation</h5>
          </mdb-card-title>
          <mdb-card-text>We specialise in transforming your business processes through the strategic integration of AI.
            By analysing existing workflows, identifying inefficiencies, and automating repetitive tasks, we streamline
            operations, enhance productivity, and drive significant cost savings. Our AI-powered solutions enable faster
            decision-making, improved accuracy, and a more agile organisation.</mdb-card-text>
        </mdb-card-body>
      </mdb-card>


      <mdb-card>
        <mdb-card-body>
          <mdb-card-title>
            <h5>Data Platform Build</h5>
          </mdb-card-title>
          <mdb-card-text>There are an overwhelming number of competing solutions in the cloud native ecosystems. The key
            is to understand
            the what, why, and how of each to make the best choices to fit your requirements. We will help you design
            and
            build a cloud-native data platform on which to build capabilities that drive competitive
            advantage.</mdb-card-text>
        </mdb-card-body>
      </mdb-card>


      <mdb-card>
        <mdb-card-body>
          <mdb-card-title>
            <h5>Dual Delivery and Upskilling</h5>
          </mdb-card-title>
          <mdb-card-text>We work collaboratively with our clients as a partner, taking a client-first and customer-first
            approach. We deliver
            on a project while upskilling your teams to be self-sufficient.</mdb-card-text>
        </mdb-card-body>
      </mdb-card>
    </div>

    <h1 #productinnovation class="card-title h1 pb-2 mt-4 dark-grey-text scroll-outer">Product Innovation</h1>
    <div class="text-center px-4 md">
      <h4>
        We offer fast-paced proof of concepts to large-scale design thinking-based service and product design programs
        to maximise
        the return on your investment. Our services help you achieve your business goals and exceed expectations through
        collaboration and co-creation.
        <br>
        <br>
      </h4>
    </div>

    <img src=" /assets/img/context.jpeg" class="img-fluid z-depth-1 mb-2" id="header_logo" alt="">

    <h4 class="dark-grey-text mt-5 mb-5">We have a passion for designing ground-breaking omnichannel services. Smart
      applications built to handle user personalisation
      with context.
      <br>
      <br> Digital now touches every step of the customer journey, with smartphones increasingly people’s default device
      for
      online.
      <br>
      <br> Understanding mobile application contextualisation is the key to unlocking great, engaging experiences for
      this new
      age of digital consumer. The benefits are huge; data consolidated omnichannel offers potential for deep analysis
      of
      customer interaction, at every level.

      <br>
      <br> Applications which best fit contextualisation are underpinned with this core philosophy of
      <strong>‘Smart Context’
      </strong>.
    </h4>

    <!-- Section: Latest Projects -->
    <section class="my-5">
      <div #latestprojects class="scroll-outer"></div>

      <!-- Section heading -->
      <h1 class="card-title h1 pb-2 mb-2 mt-3 dark-grey-text">Our latest projects</h1>
      <!-- Section description -->
      <h4 class="text-center dark-grey-text w-responsive mx-auto mb-5 dark-grey-text">We are constantly innovating,
        looking to exploit the most relevant Cloud technologies to improve customer experience.</h4>

      <mdb-card>
        <mdb-card-body>
          <!-- Grid row -->
          <div class="row">

            <!-- Grid column -->
            <div class="col-lg-5 col-xl-4 mb-5">

              <!-- Featured image -->
              <div class="view overlay rounded z-depth-1-half mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                <img src="/assets/img/Slide2.jpg" class="img-fluid z-depth-1" alt="Sample image">
                <a>
                  <div class="mask rgba-white-slight"></div>
                </a>
              </div>

            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-lg-7 col-xl-8 mb-5">

              <!-- Post title -->
              <h3 class="font-weight-bold mb-3 dark-grey-text mt-3">
                <strong>Dolo - A new breed of Cloud mobile app being developed by SmartContx.</strong>
              </h3>
              <!-- Excerpt -->
              <h5 class="dark-grey-text">Dolo functions as your contextual search assistant, built to enable information
                retrieval across phygital channels.
                A highly functional tool to aid frictionless search and discovery with content providers. Consumers
                search
                using voice, text, and computer vision, and can bookmark, list, and share document discoveries. Dolo
                provides
                content providers with tools to quickly create multimedia document artifacts: text, images, audio,
                video,
                and links to pre-existing assets like web pages and apps.</h5>
              <!-- Post data -->
              <!-- Read more button -->
              <a mdbBtn color="primary" outline="true" mdbWavesEffect class="mb-3" href="https://mydolo.co.uk/"
                target="_blank">Find out more
              </a>
            </div>


            <!-- Grid column -->
            <div class="col-lg-5 col-xl-4 mb-5">

              <!-- Featured image -->
              <div class="view overlay rounded z-depth-1-half mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                <img src="/assets/img/streetshops1.jpeg" class="img-fluid z-depth-1" alt="Sample image">
                <a>
                  <div class="mask rgba-white-slight"></div>
                </a>
              </div>

            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-lg-7 col-xl-8 mb-5">

              <!-- Post title -->
              <h3 class="font-weight-bold mb-3 dark-grey-text mt-3">
                <strong>StreetShops - The art of physical retail with the science of Digital.
                </strong>
              </h3>
              <!-- Excerpt -->
              <h5 class="dark-grey-text">StreetShops bridges the gap between online and physical shops. A platform with
                equal levels of integration
                services for large retailers and immediately accessible to smaller independents. StreetShops for the
                Shopper
                is their own smartphone and web desktop application, a feature rich shopping aid, a portal to the High
                Street.
                StreetShops equips retailers with the latest digital technologies. A shop admin application is used to
                manage
                click & collect, loyalty, product catalog, marketing, and packed with features to encourage customer
                engagement.
              </h5>
              <!-- Post data -->
              <!-- Read more button -->
              <a mdbBtn color="primary" outline="true" mdbWavesEffectclass="mb-3" href="https://streetshops.co.uk/"
                target="_blank">Find out more
              </a>

            </div>
            <!-- Grid column -->

            <!-- Grid row -->
          </div>
        </mdb-card-body>
      </mdb-card>
    </section>
    <!-- Section: Latest projects -->

    <!-- Section: Blog v.3 -->
    <section class="my-5">
      <div #blog class="scroll-outer"></div>

      <!-- Section heading -->
      <h1 class="card-title h1 pb-2 mb-2 mt-3 dark-grey-text">Recent posts</h1>
      <!-- Section description -->
      <h4 class="text-center dark-grey-text w-responsive mx-auto mb-52dark-grey-text">Want to find out more? Read our
        case studies and whitepapers to help you evolve your Cloud journey.</h4>
      <br>
      <br>
      <mdb-card>
        <mdb-card-body>
          <!-- Grid row -->
          <div class="row">

            <!-- Grid column -->
            <div class="col-lg-5 col-xl-4 mb-5">

              <!-- Featured image -->
              <div class="view overlay rounded z-depth-1-half mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                <img src="/assets/img/GenAI-RAG.png" class="img-fluid z-depth-1" alt="Gen AI with RAG">
                <a>
                  <div class="mask rgba-white-slight"></div>
                </a>
              </div>

            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-lg-7 col-xl-8 mb-5">

              <!-- Post title -->
              <h3 class="font-weight-bold mb-3 dark-grey-text mt-3">
                <strong>The Path for Enterprise Generative AI Adoption: Unlocking Value with Contextual and Agentic
                  AI.</strong>
              </h3>
              <!-- Excerpt -->
              <h5 class="dark-grey-text">Adopting Enterprise Gen AI requires more than traditional AI models like
                ChatGPT; companies must integrate contextual RAG systems and Agentic AI to create adaptable, scalable
                solutions tailored to their operations.

                At SmartContx, we guide organisations through this process, helping them connect on-premise systems with
                cloud infrastructure to unlock the full potential of AI and cloud technologies, driving innovation and
                business transformation..</h5>
              <!-- Post data -->
              <p>by
                <a class="font-weight-bold">Robin Youlton, Principal Consultant</a>, 04/10/2024
              </p>
              <!-- Read more button -->
              <a mdbBtn color="primary" outline="true" mdbWavesEffect
                href="https://www.linkedin.com/pulse/path-enterprise-generative-ai-adoption-unlocking-value-contextual-gothe/?trackingId=F2BRcP5ASNGkgUcYtArZUg%3D%3D"
                target="_blank">Read more</a>
            </div>

            <!-- Grid column -->
            <div class="col-lg-5 col-xl-4 mb-5">

              <!-- Featured image -->
              <div class="view overlay rounded z-depth-1-half mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                <img src="/assets/img/bookstore.jpeg" class="img-fluid z-depth-1" alt="Sample image">
                <a>
                  <div class="mask rgba-white-slight"></div>
                </a>
              </div>

            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-lg-7 col-xl-8 mb-5">

              <!-- Post title -->
              <h3 class="font-weight-bold mb-3 dark-grey-text mt-3">
                <strong>Customer experiences for Retail, a practical example using Google Cloud Platform.</strong>
              </h3>
              <!-- Excerpt -->
              <h5 class="dark-grey-text">Here we present a practical example, a retail consumer application with
                services harmonised across physical
                in-store and online. Google Cloud Platform (GCP) provides the application’s serverless
                Backend-as-a-Service.
                Implementing the application using a traditional N-tier architecture would involve significant
                infrastructure
                design, build, and operational complexity. Instead, we delegate complexity to GCP.</h5>
              <!-- Post data -->
              <p>by
                <a class="font-weight-bold">Robin Youlton, Principal Consultant</a>, 20/10/2021
              </p>
              <!-- Read more button -->
              <a mdbBtn color="primary" outline="true" mdbWavesEffect
                href="https://medium.com/@robin.youlton/personalised-contextual-customer-experiences-for-retail-a-practical-example-using-google-cloud-a018562828"
                target="_blank">Read more</a>
            </div>


            <!-- Grid column -->
            <div class="col-lg-5 col-xl-4 mb-5">

              <!-- Featured image -->
              <div class="view overlay rounded z-depth-1-half mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                <img src="/assets/img/cloud_is_normal.jpeg" class="img-fluid z-depth-1" alt="Sample image">
                <a>
                  <div class="mask rgba-white-slight"></div>
                </a>
              </div>

            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-lg-7 col-xl-8 mb-5">

              <!-- Post title -->
              <h3 class="font-weight-bold mb-3 dark-grey-text mt-3">
                <strong>The Cloud is normal, what’s next?
                </strong>
              </h3>
              <!-- Excerpt -->
              <h5 class="dark-grey-text">We are now faced with unprecedented technological change. The functionalities
                and scalabilities provided by
                Cloud are immense. We’re getting to a point where the "impossible” is the exception rather than the
                rule.
                There are so many potential benefits be found from integration or re-imaging business systems with
                Cloud.
                It is now possible to realise something that was previously unachievable or unimaginable, to consider
                what
                outcomes would the business achieve?</h5>
              <!-- Post data -->
              <p>by
                <a class="font-weight-bold">Robin Youlton, Principal Consultant</a>, 01/10/2021
              </p>
              <!-- Read more button -->
              <a mdbBtn color="primary" outline="true" mdbWavesEffect
                href="https://medium.com/@robin.youlton/the-cloud-is-normal-whats-next-157a5891e7dc"
                target="_blank">Read
                more</a>

            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-lg-5 col-xl-4 mb-5">

              <!-- Featured image -->
              <div class="view overlay rounded z-depth-1-half mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                <img src="/assets/img/dolo.jpeg" class="img-fluid z-depth-1" alt="Sample image">
                <a>
                  <div class="mask rgba-white-slight"></div>
                </a>
              </div>

            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-lg-7 col-xl-8 mb-5">

              <!-- Post title -->
              <h3 class="font-weight-bold mb-3 dark-grey-text mt-3">
                <strong>Planet of the phones.</strong>
              </h3>
              <!-- Excerpt -->
              <h5 class="dark-grey-text">It’s time for a fresh approach, a re-balance, we can get so much from Cloud
                integrated mobile applications.
                We need mobile app services that can deliver rich content on demand, deeply relevant bundles of
                information
                when we need them. In effect, we need new tools to let the consumer pull information when they are ready
                and willing, and functions to service them within their own space, within context. </h5>
              <!-- Post data -->
              <p>by
                <a class="font-weight-bold">Robin Youlton, Principal Consultant</a>, 10/03/2021
              </p>
              <!-- Read more button -->
              <a mdbBtn color="primary" outline="true" mdbWavesEffect
                href="https://www.linkedin.com/pulse/planet-phones-robin-youlton/" target="_blank">Read more
              </a>
            </div>
            <!-- </div> -->

            <!-- Grid column -->
            <!-- <div mdbCollapse #previousposts="bs-collapse"> -->
            <!-- Grid column -->
            <div class="col-lg-5 col-xl-4 mb-5">

              <!-- Featured image -->
              <div class="view overlay rounded z-depth-1-half mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                <img src="/assets/img/high_street.jpeg" class="img-fluid z-depth-1" alt="Sample image">
                <a>
                  <div class="mask rgba-white-slight"></div>
                </a>
              </div>

            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-lg-7 col-xl-8 mb-5">

              <!-- Post title -->
              <h3 class="font-weight-bold mb-3 dark-grey-text mt-3">
                <strong>High Street in crisis, we can help revive it.
                </strong>
              </h3>
              <!-- Excerpt -->
              <h5 class="dark-grey-text">Here we present our next generation digital platform for Physical Retail. The
                platform is built upon massively
                scalable Google Cloud, with new innovative services to harmonise high street retailers with shoppers.
              </h5>
              <!-- Post data -->
              <p>by
                <a class="font-weight-bold">Robin Youlton, Principal Consultant</a>, 10/09/2020
              </p>
              <!-- Read more button -->
              <a mdbBtn color="primary" outline="true" mdbWavesEffect
                href="https://www.linkedin.com/pulse/high-street-revival-we-can-do-robin-youlton/" target="_blank">Read
                more
              </a>
            </div>
            <!-- </div> -->

            <div class="col-lg-5 col-xl-4 mb-5">

              <!-- Featured image -->
              <div class="view overlay rounded z-depth-1-half mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                <img src="/assets/img/retailcontext.jpeg" class="img-fluid z-depth-1" alt="Sample image">
                <a>
                  <div class="mask rgba-white-slight"></div>
                </a>
              </div>

            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-lg-7 col-xl-8 mb-5">

              <!-- Post title -->
              <h3 class="font-weight-bold mb-3 dark-grey-text mt-3">
                <strong>Retail: A New Era of Consumer Apps using Cloud
                </strong>
              </h3>
              <!-- Excerpt -->
              <h5 class="dark-grey-text">How can a retailer ensure continuous customer engagement, not only through
                their online channel, but also in-store,
                synchronised as one? </h5>
              <!-- Post data -->
              <p>by
                <a class="font-weight-bold">Robin Youlton, Principal Consultant</a>, 01/04/2017
              </p>
              <!-- Read more button -->
              <a mdbBtn color="primary" outline="true" mdbWavesEffect
                href="https://www.linkedin.com/pulse/retail-new-era-consumer-applications-robin-youlton/"
                target="_blank">Read more
              </a>
            </div>
            <!-- </div> -->

            <div class="col-lg-5 col-xl-4 mb-5">

              <!-- Featured image -->
              <div class="view overlay rounded z-depth-1-half mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                <img src="/assets/img/chatbots.jpeg" class="img-fluid z-depth-1" alt="Sample image">
                <a>
                  <div class="mask rgba-white-slight"></div>
                </a>
              </div>

            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-lg-7 col-xl-8 mb-5">

              <!-- Post title -->
              <h3 class="font-weight-bold mb-3 dark-grey-text mt-3">
                <strong>Retail: Chatbots with AWS Next Generation Cloud Services</strong>
              </h3>
              <!-- Excerpt -->
              <h5 class="dark-grey-text">Chatbot capabilities get really exciting when the conversational mechanism is
                integrated with an AWS cloud
                service backend to coordinate and tailor interaction, to maintain the customer’s interest by offering
                contextually
                relevant personalised content – by knowing the customer.
              </h5>
              <!-- Post data -->
              <p>by
                <a class="font-weight-bold">Robin Youlton, Principal Consultant</a>, 21/03/2017
              </p>
              <!-- Read more button -->
              <a mdbBtn color="primary" outline="true" mdbWavesEffect
                href="https://www.linkedin.com/pulse/chatbots-aws-next-generation-cloud-services-robin-youlton/"
                target="_blank">Read more
              </a>
            </div>
            <!-- </div> -->


            <!-- <button mdbBtn color="primary" class="waves-light ml-4" type="button" (click)="previousposts.toggle()"
              mdbWavesEffect>
              <mdb-icon far icon="caret-square-down" class="mr-1"></mdb-icon>Previous posts
            </button> -->

          </div>
          <!-- Grid row -->
        </mdb-card-body>
      </mdb-card>
    </section>
    <!-- Section: Blog v.3 -->



    <h1 class="card-title h1 pb-2 mt-5 dark-grey-text">We can help shape your digital innovation.</h1>
    <img src=" /assets/img/we_can_shape.jpeg" class="img-fluid z-depth-1 mb-2" id="header_logo" alt="">
    <h4 class="text-center dark-grey-text w-responsive mx-auto mb-2 mt-4 dark-grey-text">Cloud Service Providers offer a
      comprehensive suite of services to meet enterprise needs across applications, messaging, business
      processes, data, and AI. The key to unlocking these capabilities lies in strategic integration. At SmartContx, we
      specialise in helping enterprises understand and adopt Generative AI by seamlessly connecting their on-premise
      systems with cloud infrastructure, empowering organisations to harness the full potential of AI and cloud
      technologies for business transformation.
    </h4>
    <div class="container">
      <div class="row mx-md-n5">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <img src="/assets/img/black_logo_white_background.png" class="img-fluid" id="smartcontx_logo"
            alt="SmartContx">
          <h3 style="font-style: italic;" class="dark-grey-text">
            <strong>"We start by asking ‘What-if?’."</strong>
          </h3>
          <br>
        </div>
        <div class="col-md-3"></div>
        <div class="col-md-3"></div>
        <div class="col-md-6 dark-grey-text">
          <h4>The technical functionalities and scalabilities now provided by AI & cloud services are immense. We’re
            getting
            to a
            point where the “impossible” is the exception rather than the rule.</h4>
          <br>
          <h4>We ask, if you could do something that was previously unachievable or unimaginable, what outcomes would we
            achieve?
          </h4>
          <br>
          <h4>SmartContx is a UK Cloud Consultancy. We are specialists in Amazon Web Services (AWS) and Google Cloud
            Platform
            (GCP), and above all we are passionate about developing next generation consumer applications.</h4>
        </div>
        <div class="col-md-3"></div>
      </div>
    </div>

    <div #contactus class="scroll-outer">

      <div id="CONTACT US" class="container" style="margin-top:50px ;">
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-6">
            <!-- Material form contact -->
            <!-- <div class="row">
            <div class="col-md-6"> -->
            <mdb-card>
              <mdb-card-header class="elegant-color-dark text-center white-text py-4">
                <h4>
                  <strong>Contact us</strong>
                </h4>
              </mdb-card-header>

              <!-- Card content -->
              <mdb-card-body class="px-lg-5 pt-0">
                <!-- Form -->
                <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                  <!-- Name -->
                  <div class="md-form mt-3">
                    <input type="text" id="materialContactFormName" class="form-control" mdbInput mdbValidate
                      formControlName="nameInput" />
                    <label for="materialContactFormName">Name</label>
                    <mdb-error *ngIf="nameInput.invalid && (nameInput.dirty || nameInput.touched)">Input invalid
                    </mdb-error>
                    <mdb-success *ngIf="nameInput.valid && (nameInput.dirty || nameInput.touched)">Input valid
                    </mdb-success>
                  </div>

                  <!-- E-mail -->
                  <div class="md-form">
                    <input type="email" id="materialContactFormEmail" class="form-control" mdbInput mdbValidate
                      formControlName="emailInput" />
                    <label for="materialContactFormEmail">E-mail</label>
                    <mdb-error *ngIf="emailInput.invalid && (emailInput.dirty || emailInput.touched)">Input invalid
                    </mdb-error>
                    <mdb-success *ngIf="emailInput.valid && (emailInput.dirty || emailInput.touched)">Input valid
                    </mdb-success>
                  </div>

                  <!-- Message -->
                  <div class="md-form">
                    <textarea type="text" id="materialContactFormMessage" class="form-control md-textarea" mdbInput
                      mdbValidate formControlName="message"></textarea>
                    <label for="materialContactFormMessage">Message</label>
                    <mdb-error *ngIf="message.invalid && (message.dirty || message.touched)">Input invalid</mdb-error>
                    <mdb-success *ngIf="message.valid && (message.dirty || message.touched)">Input valid</mdb-success>
                  </div>
                  <!--Facebook-->
                  <!-- <a type="button" mdbBtn floating="true" size="sm" color="fb" class="waves-light" mdbWavesEffect a
                  href="{{ (retailer | async)?.facebookurl }}" target="_blank">
                  <mdb-icon fab icon="facebook-f"></mdb-icon>
                </a> -->
                  <!-- <ngx-recaptcha2 [siteKey]="siteKey" [size]="size" [hl]="lang" [theme]="theme" [type]="type"
                  [useGlobalDomain]="false" (reset)="handleReset()" (expire)="handleExpire()" (load)="handleLoad()"
                  (success)="handleSuccess($event)" formControlName="recaptcha">
                </ngx-recaptcha2> -->
                  <div class="md-form">
                    <ngx-recaptcha2 [siteKey]="siteKey" [size]="size" [hl]="lang" [theme]="theme" [type]="type"
                      [useGlobalDomain]="false" formControlName="recaptcha">
                    </ngx-recaptcha2>
                  </div>


                  <div class="md-form">
                    <h6 style="color: blue;" (click)="termsClicked()">Terms of service</h6>
                    <div class="row" style="margin-top: 10px ;"></div>
                    <!-- <mdb-checkbox mdbValidate formControlName="terms" value="agree">
                  </mdb-checkbox>
                  <div class="row" style="margin-top: 50px;"></div>
                  <mdb-error *ngIf="terms.invalid && (terms.dirty || terms.touched)">Please accept the terms
                  </mdb-error>
                  <mdb-success *ngIf="terms.valid && (terms.dirty || terms.touched)">Terms accepted</mdb-success> -->
                  </div>
                  <div class="row" style="margin-top:50px ;"></div>
                  <!-- Send button -->
                  <button mdbBtn class="pt-5" color="success" rounded="true" block="true"
                    class="z-depth-0 my-4 waves-effect" mdbWavesEffect type="submit" [disabled]="!contactForm.valid">
                    Send
                  </button>
                </form>
                <!-- Form -->


                <h4 class="mt-5">Follow Us</h4>
                <!--Medium-->
                <a type="button" mdbBtn floating="true" size="sm" color="fb" class="waves-light" mdbWavesEffect
                  href="https://medium.com/@robin.youlton/" target="_blank">
                  <i class="fab fa-medium"></i>
                </a>
                <!-- Github -->
                <a type="button" mdbBtn floating="true" size="sm" color="fb" class="waves-light" mdbWavesEffect
                  href="https://github.com/RobinYSC/RobinYSC" target="_blank">
                  <i class="fab fa-github"></i>
                </a>
                <!--LinkedIn-->
                <a type="button" mdbBtn floating="true" size="sm" color="fb" class="waves-light" mdbWavesEffect
                  href="https://www.linkedin.com/company/smartcontx" target="_blank">
                  <i class="fab fa-linkedin-in"></i>
                </a>
                <!--Twitter-->
                <a type="button" mdbBtn floating="true" size="sm" color="tw" class="waves-light" mdbWavesEffect
                  href="https://twitter.com/SmartContx" target="_blank">
                  <i class="fab fa-twitter"></i>
                </a>
                <!--Youtube-->
                <a type="button" mdbBtn floating="true" size="sm" color="dribbble" class="waves-light" mdbWavesEffect a
                  href="https://www.youtube.com/channel/UCYmyiHaShtxNS0RR6fC_cnw" target="_blank">
                  <i class="fab fa-youtube"></i>
                </a>
                <div class="mt-3">
                  <p> 71-75 SHELTON STREET, COVENT GARDEN, LONDON ENGLAND WC2H 9JQ</p>
                  <p>Tel: +44 (0)115 990 3770</p>
                </div>
              </mdb-card-body>
            </mdb-card>
            <!-- Material form contact -->
            <!-- </div> -->
            <!-- </div> -->
          </div>
          <div class="col-md-3"></div>
        </div>
      </div>

      <!-- <img src="/assets/img/footer_image.jpeg" class="img-fluid" id="header_logo" alt=""> -->


      <!-- Footer -->
      <footer class="page-footer font-small unique-color-dark pt-0" style="margin-top:50px ;">

        <!-- Copyright -->
        <div class="footer-copyright text-center py-4">Copyright © 2024 SmartContx Limited. All rights reserved.
          <!-- <a href="https://smartcontx.com/">  SmartContx</a> -->
        </div>
        <!-- Copyright -->
      </footer>
      <!-- Footer -->