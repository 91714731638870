import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { AppComponent } from './app.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { HomeComponent } from './home/home.component';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';

import { environment } from '../environments/environment';
import { AuthService } from './service/auth.service';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { TermsComponent } from './terms/terms.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { NgxCaptchaModule } from 'ngx-captcha';

const routes: Routes = [
  {
    path: '',
    component: AppComponent
  },
  {
    path: 'foo',
    component: AppComponent
  }
]

const routerOptions: ExtraOptions = {
  anchorScrolling: "enabled",
  scrollPositionRestoration: 'enabled'
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TermsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule, RouterModule.forRoot(routes, routerOptions),
    YouTubePlayerModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MDBBootstrapModule.forRoot(),
    NgxCaptchaModule,
    NgxPageScrollCoreModule.forRoot({ duration: 500 }),
    ToastrModule.forRoot(),
        // Firebase Modules
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule.enablePersistence(),
        AngularFireAuthModule, // auth
  ],
  providers: [AuthService],
  bootstrap: [AppComponent]
})
export class AppModule { }
