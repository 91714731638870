import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';
import { User } from './user.model';

@Injectable()
export class AuthService {

  authState: any = null;

  constructor(private afAuth: AngularFireAuth, private router: Router) {
    this.afAuth.authState.subscribe((auth) => {
      this.authState = auth
    });
  }

  get isUserAnonymousLoggedIn(): boolean {
    return (this.authState !== null) ? this.authState.isAnonymous : false
  }

  get currentUserId(): string {
    return (this.authState !== null) ? this.authState.uid : '';
  }

  async anonymousLogin() {
    return this.afAuth.signInAnonymously()
      .then((user) => {
        this.authState = user;
        console.log("Logged in as annonymous");
      })
      .catch(error => console.log("THERE WWAS AN ERROR WITH LOGIN", error));
  }

  // signOut(): void {
  //   this.afAuth.signOut();
  //   this.router.navigate(['/'])
  // }

}
