// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyAHKzcXAtu5XY354P8QheOG2bpyUJGxIXE",
    authDomain: "smartcontx.firebaseapp.com",
    projectId: "smartcontx",
    storageBucket: "smartcontx.appspot.com",
    messagingSenderId: "633662431385",
    appId: "1:633662431385:web:ae0c572614ae1750b05808",
    measurementId: "G-5LPTKBESQK"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
