<mdb-card>
    <!--Card image-->
    <div class="container">
        <div class="row mx-md-n5">
            <div class="col-md-5"></div>
            <div class="col-md-2">
                <mdb-card-img src="/assets/img/anim_logo.gif" alt="SmartContx - Terms of Service"></mdb-card-img>
            </div>
            <div class="col-md-5"></div>
        </div>
    </div>
    <!--Card content-->
    <mdb-card-body>

        <!--Title-->
        <h2 class="card-title h2 pb-2">
            <strong>Terms of service</strong>
        </h2>

        <!--Text-->
        <mdb-card-text>
            <div id="text1" style="height: 4000px;">
                <h5>Privacy</h5>
                <h6>This Privacy Policy (“Policy”) governs user information collected and used on the SmartContx website.</h6>
                <hr>
                <h5>We Respect Your Right to Privacy.</h5>
                <h6>We want you to fully understand what information we collect from you and what we do with it. We do not disclose
                    your information to anyone without your prior consent. We do not sell your information. We never spam
                    you.</h6>
                <hr>
                <h5>FAQ</h5>
                <br>
                <h5>What personal details do you need to know?</h5>
                <h6>The minimum of information we ask for is your first and last name and your email address. We are able to
                    see your IP address when you log-in although we do not collect or record that information.</h6>
                <br>
                <h5>When is my information given out?</h5>
                <h6>The information you enter on the site is not visible.</h6>
                <br>
                <h5>Does SmartContx sell my information to third parties?</h5>
                <h6>We do not sell or lend any of your personal information to third parties without your prior consent except
                    under the narrow guidelines stated in this Policy (for example disclosure to service providers working
                    for us).</h6>
                <br>
                <h5>What do you use my personal details for?</h5>
                <h6>We will use your name so that we can personalise your experience with us. We will use your email address
                    to contact you if needed and to securely confirm your identity.</h6>
                <br>
                <h6>Your IP address and browser (aggregated with our other users) helps us improve our website and better understand
                    and serve our users.</h6>
                <br>
                <h5>Using Service Providers.</h5>
                <h6>We may use third parties that we refer to as internal service providers to facilitate or outsource one or
                    more aspects of the business, product and service operations that we provide to you (e.g., search technology,
                    email support) and therefore we may provide some of your personal information directly to these internal
                    service providers who are obliged to keep your information confidential.
                </h6>
                <br>
                <h5>Non-Personal, Aggregated Information.</h5>
                <h6>We disclose non-personally identifiable aggregated (gather up data across all User accounts) information
                    to advertisers and other third parties.</h6>
                <br>
                <h5>Analytics.</h5>
                <h6>SmartContx uses the services of App analytics providers such as Google Analytics to analyse traffic and usage
                    on the App. In the course of providing services, such companies may have access to Users’ IP addresses,
                    but we do not store them.</h6>
                <br>
                <h5>Law Enforcement.</h5>
                <h6>SmartContx cooperates with law enforcement inquiries, as well as other third parties to enforce laws, such
                    as: intellectual property rights, fraud and other rights, to help protect you and the website service.
                    Therefore, in response to a genuine request by law enforcement or other government officials relating
                    to a criminal investigation or alleged illegal activity, we can disclose personal information about you
                    and your event.</h6>
                <br>
                <h5>Unintended Access.</h5>
                <h6>Third parties may unlawfully intercept or access transmissions or private communications, or Users may abuse
                    or misuse your personal information that they collect from the App. Therefore, although we use industry
                    standard practices to protect your privacy, we do not promise, and you should not expect, that your personal
                    information or private communications will always remain private.</h6>
                <br>
                <h5>Acquisition.</h5>
                <h6>In event of a merger or acquisition, your personal information may be transferred to the acquiring entity,
                    and become subject to the acquirer’s data practices.</h6>
                <br>
                <h5>Will I be added to any mailing lists?</h5>
                <h6>We do not sell, rent or lend email addresses for any other purpose than in direct association with your use
                    of the web site or as otherwise described in this Policy.</h6>
                <br>
                <h5>Do you send unsolicited emails?</h5>
                <h6>SmartContx will not send any unsolicited information, including email. You will, however receive emails which
                    form an essential part of the App.</h6>
                <br>
                <h5>What do you use cookies for?</h5>
                <h6>Cookies are a standard internet technology, and many major websites use them to provide useful features for
                    their members. Cookies allow us to store and retrieve login information on a user's system. They provide
                    us with data that we can use to improve our service to you. If you so choose, you can save your login
                    or password so you don't have to re-enter them each time you visit our site. Cookies themselves do not
                    personally identify users, although they do identify a user's computer and user’s preferences. Most browsers
                    are initially set up to accept cookies. If you'd prefer, you can set yours to refuse cookies. However,
                    you may not be able to take full advantage of our App if you do so. You can control and/or delete cookies
                    as you wish – for details, see aboutcookies.org. You can delete all cookies that are already on your
                    computer and you can set most browsers to prevent them from being placed. If you do this, however, you
                    may have to manually adjust some preferences every time you visit a site and some services and functionalities
                    may not work. </h6>
                <br>
                <h5>How Do I Request a Copy of My Personal Information?</h5>
                <h6>Please make your request for a copy of your personal information to Contact Us.</h6>
                <br>
                <h5>How Do I Request Deletion of My Personal Information?</h5>
                <h6>To have your Personal Information deleted from our systems, please make your request to Contact Us.
                </h6>
                <br>
                <h5>How will I be notified of changes?</h5>
                <h6>We may amend this Privacy Policy at any time by posting the amended terms on the App. All amended terms shall
                    automatically be effective immediately after posting on the App.</h6>
                <br>
                <h5>How do I contact you?</h5>
                <h6>If you have any questions about this Privacy Policy, the practices of this web site or your dealings with
                    this web site you can contact us via the Contact Us page, or by writing to SMARTCONTX LIMITED, 71-75
                    SHELTON STREET, COVENT GARDEN, LONDON ENGLAND WC2H 9JQ</h6>
                <a mdbBtn color="primary" mdbWavesEffect (click)="exitClicked()" style="margin-top:50px ;">Exit</a>
            </div>
        </mdb-card-text>
    </mdb-card-body>
</mdb-card>